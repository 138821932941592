import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subject, takeUntil} from "rxjs";
import {AuthService} from "../auth/services/auth.service";
import {CheckInLocation} from "../../modules/check-in/check-in.models";
import {environment} from 'src/environments/environment';
import {Notification} from "../../shared/models/utils";

export enum SocketStatusEnum {
  Connecting,
  Connected,
  Closing,
  Disconnected,
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  // services
  private authService = inject(AuthService);
  // private checkInStore = inject(CheckInStore);

  private socketConnection: WebSocket | undefined;
  isConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  checkInLocation$: BehaviorSubject<CheckInLocation | undefined> = new BehaviorSubject<CheckInLocation | undefined>(undefined);
  notification$: BehaviorSubject<Notification | undefined> = new BehaviorSubject<Notification | undefined>(undefined);

  private unsubscribePing$ = new Subject<void>();

  sendToSocket(message: {
    event: string,
    data?: any,
    user?: { firstname: string, lastname: string, id: string }
  }): void {
    if (this.socketConnection?.readyState === SocketStatusEnum.Connected) {
      // console.log('Sending message', message);
      this.socketConnection.send(JSON.stringify(message));
    }
    else {
      console.warn('Socket not connected. Status: ', this.socketConnection?.readyState.toString());
    }
  }

  private handleLocation(data: CheckInLocation): void {
    this.checkInLocation$.next(data);
  }

  private handleNotification(data: Notification): void {
    const notification: Notification = {
      title: data.title,
      id: -1,
      payload: data.payload,
      message: data.message,
      isRead: false,
      createdAt: new Date().valueOf(),
      updatedAt: new Date().valueOf(),
    }
    this.notification$.next(notification);
    if (data.payload.type === 'checkin') {
      console.log('Received checkin notification', data);
      // this.checkInStore.getAll();
    }
  }

  resetData(): void {
    this.checkInLocation$.next(undefined);
  }

  disconnectFromSocket(): void {
    this.socketConnection?.close();
    // console.log('Disconnecting from socket...');
  }


  connectToSocket(): void {
    if (!this.authService.user) {
      console.error('No user found');
      return;
    }
    this.socketConnection = new WebSocket(environment.SOCKET_URL);

    this.socketConnection.onopen = (res) => {
      this.sendToSocket({
        event: 'newConnection',
        user: {
          id: this.authService.user!.id.toString(10),
          firstname: this.authService.user!.firstname,
          lastname: this.authService.user!.lastname,
        },
      });
      this.isConnected$.next(true);
      interval(30000).pipe(takeUntil(this.unsubscribePing$)).subscribe(() => {
        this.sendToSocket({
          event: 'ping',
          user: {
            id: this.authService.user!.id.toString(10),
            firstname: this.authService.user!.firstname,
            lastname: this.authService.user!.lastname,
          },
          data: {}
        });
      });
    };
    this.socketConnection.onmessage = async (msg) => {
      // console.log('Got message', msg.data);
      const content = JSON.parse(msg.data);
      const data = content.data;
      switch (content.event) {
        case 'newCheckInLocation':
          this.handleLocation(data);
          break;
        case 'notification':
          this.handleNotification(data);
          break;
        // case 'roomClosed':
        //   this.handleRoomClosed(data);
        //   break;
        // case 'receivedData':
        //   this.handleMessage(data);
        //   break;
        // case 'doubleConnection':
        //   await this.alertService.presentToast('Double connection detected with same account connected', false, 5000);
        //   this.handleMessage(data);
        //   break;
        default:
          break;
      }
    };
    this.socketConnection.onclose = async () => {
      console.warn('Socket onclose');
      this.isConnected$.next(false);
      this.unsubscribePing$.next();
    };
    this.socketConnection.onerror = async () => {
      console.error('Socket encountered error');
      this.isConnected$.next(false);
      this.unsubscribePing$.next();
    };
  }
}


// private handleNewSpectator(data: any): void {
//   this.newSpectator$.next(data);
// }
//
// private handleChatMessage(data: ChatMessage): void {
//   this.chatMessage$.next(data);
// }
//
// private handleSpectatorLeave(data: User): void {
//   this.spectatorLeave$.next(data);
// }
//
// private handleData(data: any): void {
//   this.data$.next(data);
// }
//
// private initSocket() {
//   this.socketConnection = new WebSocket(environment.socketUrl);
//   this.socketConnection.onopen = (res) => {
//     console.warn('Socket onopen...', res);
//     this.sendToSocket({
//       event: 'newConnection',
//       data: {user: this.authService.user},
//     });
//     this.isConnected$.next(true);
//   };
//   this.socketConnection.onmessage = async (msg) => {
//     const content = JSON.parse(msg.data);
//     const data = content.data;
//     switch (content.event) {
//       case 'getRooms':
//         this.handleRooms(data);
//         break;
//       case 'getRoomsById':
//         this.handleRoom(data);
//         break;
//       case 'joinRoom':
//         this.handleJoinedRoom(data);
//         break;
//       case 'roomClosed':
//         this.handleRoomClosed(data);
//         break;
//       case 'receivedData':
//         this.handleData(data);
//         break;
//       case 'newSpectator':
//         this.handleNewSpectator(data);
//         break;
//       case 'receivedChatMessage':
//         this.handleChatMessage(data);
//         break;
//       case 'spectatorLeave':
//         this.handleSpectatorLeave(data);
//         break;
//       case 'streamerLeave':
//         this.handleStreamerLeave(data);
//         break;
//       case 'streamerReconnected':
//         this.handleStreamerReconnecting(content.message);
//         break;
//     }
//   };
//   this.socketConnection.onclose = async (res) => {
//     console.error('Socket onclose...', res);
//     this.isConnected$.next(false);
//   };
//   this.socketConnection.onerror = async (err) => {
//     console.error('Socket encountered error: ', err);
//     this.isConnected$.next(false);
//   };
// }
//
// private handleStreamerLeave(data: User) {
//   this.alertService.presentToast('Streamer disconnected', false, 5000).then();
//   this.streamerLeave$.next(data);
// }
//
// private handleStreamerReconnecting(msg) {
//   this.alertService.presentToast('Streamer reconnected', true, 5000).then();
//   this.streamerReconnect$.next();
// }
// }
